var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          ref: "vspopup-payment",
          attrs: {
            title: _vm.$t("marketing_place.purchase_summary_payment"),
            active: _vm.visible,
            id: "popup-payment",
          },
          on: {
            "update:active": function ($event) {
              _vm.visible = $event
            },
            close: _vm.closePopup,
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closePopup(false)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "m-5" },
            [
              _c(
                "vs-tabs",
                [
                  _c(
                    "vs-tab",
                    {
                      attrs: {
                        label: _vm.$t("marketing_place.purchase_summary"),
                        icon: "list",
                      },
                    },
                    [_c("CartResume", { attrs: { cartItems: _vm.cartItems } })],
                    1
                  ),
                  _c(
                    "vs-tab",
                    {
                      attrs: {
                        label: _vm.$t("marketing_place.payment"),
                        icon: "attach_money",
                      },
                    },
                    [_vm._v(" ffsdfsd ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }