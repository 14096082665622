<template>
  <div>
    <div class="relative">
      <div class="vx-navbar-wrapper navbar-full p-5">
        <vs-navbar class="navbar-custom navbar-skelton" :class="'items-end'">
          <vs-spacer />
          <CartDropDown :cartItems="cart.items" @updateCartHandling="updateCartHandling" @paymentPurchasePopup="showPaymentPopup = true"/>
          <CartResumeSideBar :cartItems="cart.items" @updateCartHandling="updateCartHandling"/>
        </vs-navbar>
      </div>
    </div>
    <div class="m-5">
      <vx-card class="mb-4"
        :title="$t('marketing_place.title', [institutionName])">

        <ProductGridCardView :encrypParam="encrypParam"
                              @updateCartHandling="updateCartHandling"
                              :validatedExistProductAdd="validatedProductFromCart"/>

      </vx-card>
    </div>
    <shopping-checkout
        :cartItems="cart.items"
        :visible.sync="showPaymentPopup"
        @closeShoppingCheckoutPopup="showPaymentPopup = false"/>
  </div>
</template>

<script>

import standard from '@/services/standard'
import store from '@/store/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import ProductGridCardView from './ProductGridCardView'
import CartDropDown from './CartDropDown'
import CartResumeSideBar from './CartResumeSideBar'

import ShoppingCheckout from './ShoppingCheckout'


import MarketingPlaceService from '@/services/api/MarketingPlaceService'
import CartService from '@/services/api/CartService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ProductGridCardView,
    CartDropDown,
    CartResumeSideBar,
    ShoppingCheckout
  },
  props: {
    encrypParam: {
      default: null,
      type: String
    }
  },

  data: () => ({
    shopp: null,
    cart: {
      items: []
    },
    service: null,
    cartService: null,
    showPaymentPopup: false,

  }),
  computed: {
    validateForm() {

    },
    institutionName (){
      return ''
    }
  },
  beforeMount() {
    this.service = MarketingPlaceService.build(this.$vs)
    this.cartService = CartService.build(this.$vs)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      if(!this.isUserLogged()){
        return
      }
      this.$vs.loading()
      this.cartService.getOpenCart(false).then(
        response => {
          this.cartId = _.get(response, 'cart_id')
          if(response){
            this.cart = response
          }
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(() => {this.$vs.loading.close()})
    },
    updateCartHandling(item){
      this.loadData()
    },
    isUserLogged() {
      return this.$store.state.auth.isUserLoggedIn()
    },
    validatedProductFromCart(item){
      let productId = item.id

      let cartItem = this.cart.items.filter(cartItem => {
          return cartItem.product_id === productId ? cartItem : null
      })

      if(cartItem && cartItem.length > 0){
        this.notifyProductAdded(cartItem[0].product_name)
        return true
      }
    },
    notifyProductAdded(productName){
      this.$vs.notify({
        time: 2000,
        title: this.$t('atencao'),
        text: this.$t('marketing_place.product_added', [productName]),
        position: 'top-center',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
    },
    paymentPurchase(){
      this.showPaymentPopup = true
    },
    closeShoppingCheckoutPopup(show){
      this.showPaymentPopup = show
    }
  },
}
</script>

<style lang="scss" scoped>
  vs-navbar.navbar-custom.vs-con-items{
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

</style>
