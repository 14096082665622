<template>
  <div>
    <vs-popup :title="$t('marketing_place.purchase_summary_payment')"
              :active.sync="visible"
              @close="closePopup"
              id="popup-payment"
              ref="vspopup-payment"
              @keyup.esc="closePopup(false)">
      <div class="m-5">
          <vs-tabs>
            <vs-tab :label="$t('marketing_place.purchase_summary')" icon="list">
              <CartResume :cartItems="cartItems"/>
            </vs-tab>
            <vs-tab :label="$t('marketing_place.payment')" icon="attach_money">
                  ffsdfsd
            </vs-tab>
          </vs-tabs>
      </div>
    </vs-popup>
  </div>
</template>
<script>

import standard from '@/services/standard'
import store from '@/store/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import CartResume from './CartResume'


import MarketingPlaceService from '@/services/api/MarketingPlaceService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    CartResume,
  },
  props: {
    visible :{
      type: Boolean,
      default: false
    },
    cartItems : {type: Array, default: () => [], required: true}
  },

  data: () => ({
    shopp: null,
    service: null
  }),
  computed: {
    validateForm() {

    },
    institutionName (){
      return ''
    }
  },
  beforeMount() {
    this.service = MarketingPlaceService.build(this.$vs)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {

    },
    closePopup(value){
      this.visible = value
      this.$emit('closeShoppingCheckoutPopup', value)
    },
    isUserLogged() {
      return this.$store.state.auth.isUserLoggedIn()
    }
  },
}
</script>

<style lang="scss" scoped>
  vs-navbar.navbar-custom.vs-con-items{
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

</style>
